<template>
    <filter-list
        v-model="list"
        ref="list"
        url="/patentDemand/all"
        :filters="filters"
        :sortKey="sortKey"
        isDemand
        @btnEvent="addInfo"
        @init="init"
    >
        <template v-for="item in list">
            <patent-demand-row :key="item.id" :info="item"></patent-demand-row>
        </template>
        <patent-demand-add ref="public" @refreash="refreash"></patent-demand-add>
    </filter-list>
</template>

<script>
import FilterList from '../../components/page/FilterList.vue';
import { patentTypeOptions, patentStatusOptions, tradingMethodOption, moneyOptions } from '../../utils/variables';
import PatentDemandRow from '../../components/list/patentDemandRow.vue';
import PatentDemandAdd from '../../components/popup/PatentDemandAdd.vue';
export default {
    components: { FilterList, PatentDemandRow, PatentDemandAdd },
    data() {
        return {
            patentTypeOptions,
            patentStatusOptions,
            tradingMethodOption,
            moneyOptions,
            industryClassOptions: [], //领域,
            industryClassOption: [], //领域,
            list: [],
            sizeForm: {},
            sortKey: [
                // {
                //     name: '发布时间',
                //     key: 'createdAt'
                // },
                // {
                //     name: '预算',
                //     key: 'expectedPrice'
                // },
                {
                    name: '浏览量',
                    key: 'view'
                }
            ]
        };
    },
    created() {
        this.init();
    },
    computed: {
        filters() {
            const type = [this.DECLASSIFICATION, this.GENERAL];
            return type[this.$route.query.caseType == 'DECLASSIFICATION' ? 0 : 1];
        },
        DECLASSIFICATION() {
            return [
                {
                    name: '所属领域',
                    key: 'industryClass',
                    list: [...this.industryClassOptions]
                },
                // {
                //     name: '法律状态',
                //     key: 'patentStatus',
                //     list: [...this.patentStatusOptions]
                // },
                {
                    name: '交易方式',
                    key: 'modeId',
                    list: [...this.tradingMethodOption]
                }

                // {
                //     name: '预算区间',
                //     key: 'expectedPrice',
                //     list: [...this.moneyOptions]
                // },
                // {
                //     name: '发布时间',
                //     key: 'createdAt',
                //     list: [...this.createdAtOptions]
                // }
            ];
        },
        GENERAL() {
            return [
                {
                    name: '所属领域',
                    key: 'industryClass',
                    list: [...this.industryClassOption]
                },
                {
                    name: '专利类型',
                    key: 'patentTypeId',
                    list: [...this.patentTypeOptions]
                },
                {
                    name: '法律状态',
                    key: 'patentStatusId',
                    list: [...this.patentStatusOptions]
                },

                {
                    name: '交易方式',
                    key: 'modeId',
                    list: [...this.tradingMethodOption]
                },
                // {
                //     name: '预算区间',
                //     key: 'expectedPrice',
                //     list: [...this.moneyOptions]
                // },
                // {
                //     name: '发布时间',
                //     key: 'createdAt',
                //     list: [...this.createdAtOptions]
                // },
                {
                    name: '技术来源',
                    key: 'ownerType',
                    list: [...this.sourceTechnology]
                }
            ];
        },
        createdAtOptions() {
            return [
                {
                    label: '一周内',
                    value: this.getTimeBefor()
                },
                {
                    label: '一月内',
                    value: this.getTimeOnem()
                },
                {
                    label: '二月内',
                    value: this.getTimeTom()
                },
                {
                    label: '三月内',
                    value: this.getTimeTherrm()
                },
                {
                    label: '三月以上',
                    value: this.getTimeTherrms()
                }
            ];
        },
        sourceTechnology() {
            return [
                {
                    label: '高校',
                    value: 'UNIVERSITIES_AND_INSTITUTES'
                },
                {
                    label: '企业',
                    value: 'ENTERPRISES_AND_INSTITUTIONS'
                },
                {
                    label: '研究院',
                    value: 'RESEARCH'
                }
            ];
        }
    },
    methods: {
        init() {
            this.$http
                .post('/setting/byFlag', { flag: 13 })
                .then(res => {
                    if (res.length > 0) {
                        this.industryClassOptions = res.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            };
                        });
                    }

                    this.$nextTick(() => {
                        this.$refs.list.init();
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
            this.$http
                .post('/setting/byFlag', { flag: 1 })
                .then(res => {
                    if (res.length > 0) {
                        this.industryClassOption = res.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            };
                        });
                    }

                    this.$nextTick(() => {
                        this.$refs.list.init();
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        },
        addInfo(value) {
            if (value) {
                // this.$refs.public.init(this.$route.query.caseType);
                this.checkLogin(false).then(() => {
                    this.$refs.public.init(this.$route.query.caseType);
                });
            }
        },
        refreash() {
            this.$refs.list.getData();
            console.log(this.$refs.list);
        }
    }
};
</script>

<style lang="less" scoped></style>
