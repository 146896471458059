<template>
    <el-dialog
        v-loading="saving"
        :title="`发布需求信息-${formData.caseType === 'GENERAL' ? '专利' : '脱密专利'}`"
        center
        :visible.sync="show"
        width="980px"
    >
        <el-form
            :model="formData"
            :rules="rules"
            ref="form"
            label-width="200px"
            label-position="right"
            size="small"
            inline
        >
            <el-form-item
                prop="name"
                :label="`${formData.caseType === 'GENERAL' ? '需求专利名称' : '需求脱密专利名称'}`"
            >
                <el-input style="width: 400px" v-model="formData.name" placeholder="例：一种***方法 / 一种***装置"></el-input>
            </el-form-item>
            <el-form-item
                prop="keyword"
                :label="`${formData.caseType === 'GENERAL' ? '需求专利包含关键字' : '需求脱密专利包含关键字'}`"
            >
                <el-input
                    style="width: 400px"
                    v-model="formData.keyword"
                    placeholder="请输入需求脱密专利包含关键字"
                ></el-input>
            </el-form-item>
            <el-form-item v-if="formData.caseType === 'GENERAL'" prop="patentTypeId" label="专利类型">
                <el-select v-model="formData.patentTypeId" clearable filterable placeholder="请选择" style="width: 26%">
                    <el-option
                        v-for="item in patentTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="patentStatusId" v-if="formData.caseType === 'GENERAL'" label="专利状态">
                <el-select v-model="formData.patentStatusId" clearable filterable placeholder="请选择" style="width: 26%">
                    <el-option
                        v-for="item in patentStatusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="industryClass" label="行业分类" v-if="formData.caseType === 'GENERAL'">
                <el-select
                    v-model="formData.industryClass"
                    clearable
                    filterable
                    placeholder="请选择"
                    style="width: 26%"
                >
                    <el-option v-for="item in industryOption" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="industryClass" label="行业分类" v-else>
                <el-select
                    v-model="formData.industryClass"
                    clearable
                    filterable
                    placeholder="请选择"
                    style="width: 26%"
                >
                    <el-option
                        v-for="item in industryOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="description" label="需求描述" class="block">
                <el-input
                    type="textarea"
                    v-model="formData.description"
                    maxlength="500"
                    show-word-limit
                    :autosize="{ minRows: 6, maxRow: 6 }"
                    placeholder="请填写需求描述，500字以内"
                    resize="none"
                ></el-input>
            </el-form-item>
            <el-form-item label="交易方式" prop="modeId">
                <el-radio-group v-model="formData.modeId">
                    <el-radio v-for="item in tradingMethodOption" :key="item.value" :label="item.value">
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="expectedPrice" label="预算(万元)">
                <el-input-number
                    v-model="formData.expectedPrice"
                    :disabled="formData.negotiateDirectly"
                    @change="Price"
                    :precision="1"
                    :controls="false"
                    placeholder="请输入"
                    style="margin-right: 10px"
                >
                </el-input-number>
                <el-checkbox @change="Directly" v-model="formData.negotiateDirectly">面议</el-checkbox>
            </el-form-item>

            <el-form-item prop="contact" label="联系人">
                <el-input v-model="formData.contact" placeholder="请输入联系人"></el-input>
            </el-form-item>

            <el-form-item prop="phone" label="联系电话">
                <el-input style="width: 200px" v-model="formData.phone" placeholder="请输入联系电话"></el-input>
            </el-form-item>

            <el-form-item prop="address" label="所在地区">
                <el-input style="width: 400px" v-model="formData.address" placeholder="请输入所在地址"></el-input>
            </el-form-item>

            <el-form-item prop="email" label="邮箱">
                <el-input style="width: 200px" v-model="formData.email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
        </el-form>
        <div class="btns">
            <el-button type="info" size="small" @click="show = false">返回</el-button>
            <el-button type="primary" size="small" @click="onSave">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { tradingMethodOption, patentStatusOptions, patentTypeOptions, phonePattern } from '../../utils/variables';
import { mapState } from 'vuex';
export default {
    name: 'PatentDemandEdit',
    created() {
        this.$http
            .post('/setting/byFlag', { flag: 13 })
            .then(res => {
                if (res.length > 0) {
                    res.forEach(item => {
                        this.industryOptions.push({
                            label: item.name,
                            value: item.id
                        });
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
        this.$http
            .post('/setting/byFlag', { flag: 1 })
            .then(res => {
                if (res.length > 0) {
                    res.forEach(item => {
                        this.industryOption.push({
                            label: item.name,
                            value: item.id
                        });
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
    },
    data() {
        return {
            needChange: true,
            show: false,
            saving: false,
            formData: {
                caseType: 'GENERAL'
            },
            industryOptions: [],
            industryOption: [],
            tradingMethodOption,
            patentStatusOptions,
            patentTypeOptions
        };
    },
    computed: {
        ...mapState(['userInfo']),
        types() {
            return this.$route.query.caseType;
        },
        rules() {
            return {
                name: [
                    {
                        required: true,
                        message: `请输入需求${this.types === 'DECLASSIFICATION' ? '脱密专利' : '专利'}名称`
                    }
                ],
                // keyword: [
                //     {
                //         required: true,
                //         message: `请输入需求${this.types === 'DECLASSIFICATION' ? '脱密专利' : '专利'}包含关键字`
                //     }
                // ],
                // patentTypeId: [{ required: true, message: '请选择专利类型' }],
                // patentStatusId: [{ required: true, message: '请选择法律状态' }],
                // industryClass: [{ required: true, message: '请选择所属领域' }],
                // description: [{ required: true, message: '请填写需求描述' }],
                // modeId: [{ required: true, message: '请选择交易方式' }],
                // expectedPrice: [
                //     {
                //         required: true,
                //         validator: (rule, value, callback) => {
                //             if (!this.formData.negotiateDirectly) {
                //                 if (!this.formData.expectedPrice) {
                //                     callback(new Error('请选择预算方式'));
                //                 } else {
                //                     callback();
                //                 }
                //             } else {
                //                 callback();
                //             }
                //         }
                //     }
                // ],
                // contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                // phone: [{ required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' }],
                // email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                // address: [{ required: true, message: '请输入所在地区' }]
            };
        }
    },
    methods: {
        Price() {
            if (this.formData.expectedPrice) {
                this.formData.negotiateDirectly = false;
            }
        },
        Directly() {
            if (this.formData.negotiateDirectly == true) {
                this.$set(this.formData, 'expectedPrice', undefined);
            }
            this.$forceUpdate();
        },
        init(types) {
            this.show = true;
            this.$nextTick(() => {
                this.formData = {
                    userId: this.userInfo.id,
                    caseType: types,
                    // mode: 'TRANSFER',
                    negotiateDirectly: false,
                    contact: this.userInfo.nickname,
                    phone: this.userInfo.phone,
                    email: this.userInfo.email
                };
                this.$refs.form.resetFields();
            });
        },
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                    setTimeout(() => {
                        this.$confirm('发布成功，正在等待平台审核', '提示', {
                            confirmButtonText: '继续发布',
                            cancelButtonText: '退出',
                            type: 'warning'
                        }).then(() => {
                            setTimeout(() => {
                                this.init(this.types);
                            }, 1000);
                        });
                        // .catch(() => {
                        //     this.show = false;
                        // });
                    }, 1000);
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData, status: 'PENDING' };
            // console.log(data);
            this.saving = true;
            this.$http
                .post('/patentDemand/save', data, { body: 'json' })
                .then(() => {
                    this.saving = false;
                    this.$message.success('发布成功');
                    this.show = false;
                    this.$emit('refreash');
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        }
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog .el-form-item {
    width: 100%;
}
.el-tag {
    margin-bottom: 11px;
}
.el-tag + .el-tag {
    margin-left: 10px;
    background-size: 100% 100%;
}
.input-new-tag {
    width: 100%;
    vertical-align: bottom;
}
/deep/ .el-checkbox__inner {
    border-radius: 50%;
}
/deep/ .el-dialog__header {
    padding: 17px 0px !important;
}
/deep/ .el-form {
    width: 100% !important;
    margin: 0px !important;
}
/deep/ .el-select{
    width: 93% !important;
}
</style>
